import axios from "axios";
import headerConfig from './api_header_config'

const header=headerConfig.api_header_epiboly
//封装get
export function get(url,params){
    return  axios.get(header+url,{
        params
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}

export function getEpb(url,params){
    return  axios.get(header+url,{
        params,
        headers:{"channel":'admin'}
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}


export function getEpbXtoken(url,params){
    let token
    if (header === 'https://yunicu-api.yunicu.com') { // 判断正式环境还是测试环境
        token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjkiLCJuYW1lIjoiQXNoZSJ9.z5osL59T5Z6wyhd-oLBACr1sm3aRMCmDG0GScbI6e_s'
    }else {
        token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjkiLCJuYW1lIjoi5pu55oWn54-NIn0.21L7QKLUsBlcnNusi-8fO6U2AbM3-QDISBy-2HMuu8s'
    }
    return  axios.get(header+url,{
        params,
        // 正式：eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjkiLCJuYW1lIjoiQXNoZSJ9.z5osL59T5Z6wyhd-oLBACr1sm3aRMCmDG0GScbI6e_s
        // 测试：eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjkiLCJuYW1lIjoi5pu55oWn54-NIn0.21L7QKLUsBlcnNusi-8fO6U2AbM3-QDISBy-2HMuu8s
        headers:{"x-token":token,'channel':'admin'}
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}

export function post(url,params){
    return  axios.post(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function put(url,params){
    return  axios.put(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function del(url,params){
    return  axios.delete(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
