import {get, post, put, del, getExel,putUserId,delUserId,postUserId,putXtoken,postXtoken,delXtoken} from './base_y'
import qs from 'qs'

//--------------------------------------------医学会议-点播回放-------------------------------
//医学会议-点播回放
export function get_demand_playback_list(id,data){
    // return get("/v3/vod/contents?conventionId="+id,data)
    return get("/api/admin2/vod/contents?conventionId="+id,data) //冯修改
}
//获取单个
export function get_demand_playback_one_list(id){
    // return get("/v3/vod/content/"+id)
    return get("/api/admin2/vod/content/"+id) //冯修改
}
export function get_video_one_list(id){
    return get("/yun/vod/?id="+id)
}

export function demand_playback_list_upDown(id,moveUpDown){
    // return post("/v3/vod/moveUpDown/"+id +'?moveUpDown=' + moveUpDown)
    return post("/api/admin2/vod/moveUpDown/"+id +'?moveUpDown=' + moveUpDown) //冯修改
}
//获取签名
export function get_signature(){
    // return get("/v3/getUgcUploadSign")
    return get("/api/admin2/getUgcUploadSign")
}
//获取专家的姓名和id
export function get_experts_id(name,pageNum,pageSize){
    // return get("/v3/experts",{name,pageNum,pageSize})
    return get("/api/admin2/experts",{name,pageNum,pageSize}) //冯修改
}
//获取视频url
export function get_videoLink(videoId){
    // return get("/v3/videoLink",{videoId})
    return get("/api/admin2/videoLink",{videoId}) //冯修改
}
//上传视频
export function post_video(fileId,type,data){
    return post("/v3/vod/video/upload"+'?fileId='+fileId+'&type='+type,data)
}
//上传视频
export function put_video_two(id,type,data){
    return put("/v3/vod/content/"+id+'?type='+type,data)
}
//上传视频
export function put_video(id,type,fileId,data){
    // return put("/v3/vod/content/"+id+'?type='+type+'&fileId='+fileId,data)
    return put("/api/admin2/vod/content/"+id+'?type='+type+'&fileId='+fileId,data) //冯修改
}
//删除
export function del_videoList(id){
    // return del("/v3/vod/content/"+id)
    return del("/api/admin2/vod/content/"+id) //冯修改
}
//--------------------------------------------点播视频管理-------------------------
//点播管理
export function get_column_vods(data){
    return get('/v3/column/vods',data)
}
//新增
export function post_video_upload(type,data){
    return post("/v3/vod/video/upload"+'?type='+type,data)
}
//上移下移
export function column_vods_moveUpDown(id,moveUpDown,code){
    return post('/v3/vod/moveUpDown/'+id+'?moveUpDown='+moveUpDown+'&code='+code)
}
//通过关键字获取视频
export function get_keyWord_vods(keyword){
    return get('/v3/vod/videos',{keyword})
}
//选择视频
export function post_choose_vods(vodId,videoId,data){
    return post('/v3/Max/item/video/'+vodId+'/'+videoId,data)
}
//获取要选择的医学会议
export function get_case_room_vods(pageNum,pageSzie,title){
    return get('/v3/vod/conventions',{pageNum,pageSzie,title})
}
//导入视频
export function post_lead_vods(vodId,conventionId){
    return post('/v3/Max/item/'+conventionId+'?vodId='+vodId)
}
//关联会议---导入视频
export function post_lead_vods_two(vodId,conventionId){
    return post('/v3/Max/item/'+conventionId+'?vodId='+vodId)
}
//获取会议视频的数量
export function get_vods_num(conventionId){
    return get('/v3/convention/vod/'+conventionId)
}
//专题视频的上移下移
export function post_lead_vods_moveUpDown(id,vodId,moveUpDown){
    return post('/v3/vod/max/moveUpDown/'+id+'/'+vodId+'?moveUpDown='+moveUpDown)
}
//移除视频---专题
export function del_list_zhuan_vods(id){
    return del('/v3/vod/max/'+id)
}
//获取专题数据
export function get_vods_topic(vodId,title){
    return get('/v3/vod/topic',{vodId,title})
}
//获取片头片尾
export function get_startEnd(type,pageNum,pageSize,title){
    // return get('/v3/shorts',{type,pageNum,pageSize,title})
    return get('/api/admin2/shorts',{type,pageNum,pageSize,title}) //冯修改
}
//获取专题列表数据
export function get_specials_list(data){
    return get('/v3/topics',data)
}
//修改视频库管理专题
export function put_video_topic(maxId,id){
    return put('/v3/yun/video/topic/'+maxId+'?id='+id)
}
//--------------------------------------------新点播视频管理--------------------------------
//获取栏目
export function getColumnVodsList(){
    return get('/yun/column')
}
//查询点播列表
export function getVodsList(data){
    return get('/yun/vod',data)
}
//获取云ICU点播视频（栏目）
export function getVodsListL(data){
    return get('/yun/vod/selVodFromColumn',data)
}
//获取云ICU点播视频（大会）
export function getVodsListLD(data){
    return get('/yun/vod/selVodFromConvention',data)
}


//新增点播
export function postVodsList(data){
    return postXtoken('/yun/vod',data)
}
//修改点播
export function putVodsList(data){
    return putXtoken('/yun/vod',data)
}
export function getColumnByVodId(id){
    return get('/yun/getColumnByVodId/'+id)
}
export function selConventionTitleByVodId(id){
    return get('/yun/selConventionTitleByVodId/'+id)
}
//移除视频---专题 (关联最后一条时执行真删除操作)
export function delVodTrue(id){
    return delXtoken('/yun/vod?id='+id)
}
export function delVods(data){
    return putXtoken('/yun/vod',data)
}
// export function delVod(data){
//     return delXtoken('/yun/vod',data)
// }
export function delVod(data){
    return putXtoken('/yun/delVod',data)
}
//删除点播（栏目）
export function delVodL(id){
    return delXtoken('/yun/delVodCol/'+id)
}
//删除点播（大会）
export function delVodD(id){
    return delXtoken('/yun/delVodCon/'+id)
}

//点播上移下移
export function columnVodsMoveUpDown(data){
    return putXtoken('/yun/vod/upOrdown',qs.stringify(data))
}
//获取会议列表信息
export function getCaseRoomVods(data){
    return get('/yun/vod/conventionInfo',data)
}
//导入会议点播视频
export function postLeadVods(id,conventionId){
    return postXtoken('/yun/vod/insMixItemByCon?id='+id+'&conventionId='+conventionId)
}
//根据视频标题搜索视频库
export function getKeyWordVods(data){
    return get('/yun/vod/selVideoManageByTitle',data)
}
//根据文章标题搜索视频库
export function getArticleVods(data){
    return get('/yun/vod/selArticleByTitle',data)
}
//根据视频id添加到专题目录
export function postChooseVods(vodId,videoManageId){
    return postXtoken('/yun/vod/insItemFromVideoManage?vodId='+vodId+'&videoManageId='+videoManageId)
}
//根据文章id添加到专题目录
export function postChooseArticle(vodId,articleId){
    return postXtoken('/yun/vod/insItemFromArticle?vodId='+vodId+'&articleId='+articleId)
}
//新增单视频到点播
// export function postInsSingleByManageId(columnCode,manageId,conventionId){
//     return postXtoken('/yun/vod/insSingleByManageId?columnCode='+columnCode+'&manageId='+manageId+'&conventionId='+conventionId)
// }
export function postInsSingleByManageId(data){
    return postXtoken('/yun/vod/insSingleByManageId',data)
}
//查询专题目录
export function getVodsTopic(data){
    return get('/yun/vod/getMixItem',data)
}
//移除视频---专题
export function delZhuanVods(id){
    return delXtoken('/yun/vod/delMixItem?id='+id)
}
//专题视频的上移下移
export function postLeadVodsMoveUpDown(data){
    return putXtoken('/yun/vod/upOrdownOfMixItem',qs.stringify(data))
}
//获取视频库列表
export function getVideoList(data){
    return get('/yun/videoManage',data)
}
//新增视频库
export function postVideoManage(data){
    return postXtoken('/yun/videoManage',data)
}
//修改视频库
export function putVideoManage(data){
    return putXtoken('/yun/videoManage',data)
}
//删除
export function delVideoList(id){
    return delXtoken("/yun/videoManage",{id})
}
//获取专题列表数据
export function getSpecialsList(data){
    return get('/yun/videoManage/getVodTopic',data)
}
//----------------------------片头片尾管理--------------------------------------------------------
export function get_opening_credits(data){
    return get('/v3/yun/shorts',data)
}
//修改片头片尾
export function put_startEnd(id,data){
    return put('/v3/yun/short/'+id,data)
}
//---------------------------文章管理-----------------------------------------------------
export function get_active_list(expert,title,code,pageNo,pageNum, time){
    return get('/v3/yun/platform/article?pageNo='+pageNo+'&pageNum='+pageNum+'&title='+title+'&code='+code+'&expert='+expert + '&time=' + time)
}
export function del_active_list(id) {
    return delUserId('/v3/yun/platform/article/'+id)
}
//move: up:上移，down：下移
export function move_active_list(id,move) {
    return putUserId('/v3/yun/platform/article/'+id+'/'+move)
}
export function post_active_list(data) {
    return postUserId('/v3/yun/platform/article',data)
}
export function put_active_list(data) {
    return putUserId('/v3/yun/platform/article',data)
}

//-----------------------------------直播管理功能设置--------------------------------------
export function get_function_list(id){
    return get('/yun/liveStream?id='+id)
}
// export function put_function_list(userId,id,data){
//     return putUserId('/yun/liveStream?userId='+userId+'&id='+id,data)
// }
export function put_function_list(id,data){
    return putXtoken('/yun/liveStream?id='+id,data)
}
// //获取直播回放列表--内部出的接口（hx）
//回放列表
export function get_Playback_list_yun(liveStreamId){
    return get("/yun/liveStreamVideo?liveStreamId="+liveStreamId)
}
//上移下移
export function put_Playback_UpOrDown(id,type){
    return putXtoken("/yun/liveStreamVideo/UpOrDown?id="+id+"&type="+type)
}



//---------------------------------直播推广---------------------------------------
// app推广
export function getAppPromotion(id){
    // return get("/v3/yun/promote/apps/"+id)
    return get("/api/admin2/yun/promote/apps/"+id) //冯修改
}
// app推广
export function postAppPromotion(data){
    // return post("/v3/yun/promote/app",data)
    return post("/api/admin2/yun/promote/app",data) //冯修改
}
// 关闭推广
export function delAppPromotion(id,data){
    return del("/api/admin/yun/promote/"+id,data)
}

// 社区推广
export function getcommunityPromotion(id){
    // return get("/v3/yun/promote/community/"+id)
    return get("/api/admin2/yun/promote/community/"+id)  //冯修改
}
// export function getcommunityPromotion(id){
//     return get("/v3/yun/promote/community?id="+id)
// }
// 社区推广
export function postcommunityPromotion(data){
    return post("/v3/yun/promote/post",data)
}

//--------------------------------------------------注册会员----------------------------------------
// 查询会员
export function getMemberList(data){
    // return get("/v3/yun/Member",data)
    return get("/api/admin2/yun/Member",data) //冯修改
}
// 查询单个会员
export function getMemberOneList(id){
    return get("/v3/yun/getMemberById?id="+id)
}

//
//添加视频库
export function post_video_list_h(streamId,videoManegeId){
    return postXtoken("/api/admin/liveStreamVideo/"+streamId+'/'+videoManegeId)
}

//新增会员
export function postMember(data){
    // return postXtoken("/v3/yun/Member",data)
    return postXtoken("/api/admin2/yun/Member",data) //冯修改
}
//修改会员
export function putMember(id,data){
    // return putXtoken("/v3/yun/Member?id="+id,data)
    return putXtoken("/api/admin2/yun/Member?id="+id,data) //冯修改
}

//获取根据病历模板id获取已上传病例
export function getCasePatientTemp(data){
    return get("/v3/yun/casepatienttemp",data)
}

//-------------------------------------直播会议总览------------------------------------------
export function getLiveStreamOverview(type){
    return get("/yun/liveStreamOverview",{type})
}


//----------------------------------------会议结算-我的会议管理-------------------------------------
//我的会议管理
export function myManageList(data){
    return get("/yun/getAuditLiveStream",data)
}

export function manageListDetail(streamId){
    return get("/yun/selLiveStreamAll",{streamId})
}

//----------------------------------------其他会议管理-------------------------------------
//其他会议管理
export function otherManageList(data){
    return get("/yun/OtherMeeting",data)
}

export function postOtherManageList(data){
    return postXtoken("/yun/OtherMeeting",data)
}
export function putOtherManageList(data){
    return putXtoken("/yun/OtherMeeting",data)
}

//查询人员名单
export function listPersons(data){
    return get("/yun/LogMeetExpert",data)
}
//新增人员
export function postListPersons(data){
    return postXtoken("/yun/LogMeetExpert",data)
}

// 通过手机号查询会员
export function getMemberListTel(data){
    // return get("/v3/yun/memberByTel",data)
    return get("/api/admin2/yun/memberByTel",data) //冯修改
}
//修改其他会议管理
export function putListPersons(data){
    return putXtoken("/yun/OtherMeeting",data)
}
//删除
export function delListPersons(id){
    return delXtoken("/yun/LogMeetExpert",{id})
}
//修改人员名单（通过-驳回-打款）
export function putLogMeetExpert(data){
    return putXtoken("/yun/LogMeetExpert",data)
}
//医院名称查询
export function hospitalByName(data){
    return get("/yun/selHospitalByName",data)
}
//批量导出
export function ExportBulkList(data){
    return getExel("/yun/LogMeetExpertExcel",data)
}
//资料负责人
export function DataManager(name){
    return get("/yun/OtherMeetingFuZeUsers",{name})
}
//其他会议管理明细（总金额，总数，待审核等）
export function selMtOtDetail(data){
    return get("/yun/selMtOtMingXi",data)
}
//修改点播列表下移（栏目）
export function colVodDown(id){
    return putXtoken("/yun/vod/colVodDown/"+id)
}
//修改点播列表上移（栏目）
export function colVodUp(id){
    return putXtoken("/yun/vod/colVodUp/"+id)
}
//修改点播列表下移（大会）
export function conVodDown(id){
    return putXtoken("/yun/vod/conVodDown/"+id)
}
// 修改点播列表上移（大会）
export function conVodUp(id){
    return putXtoken("/yun/vod/conVodUp/"+id)
}
//大会或栏目集合新增
export function insVodConOrCol(data){
    return postXtoken("/yun/vod/insVodConOrCol",data)
}
//删除点播关联的大会
export function delVodConList(id,conventionId){
    return delXtoken("/yun/delVodConList/"+id+'/'+conventionId)
}
//删除点播关联的栏目
export function delVodColList(id,code){
    return delXtoken("/yun/delVodColList/"+id+'/'+code)
}
export function isTuiGuang(id){
    return get("/yun/vod/isTuiGuang/"+id)
}
